<template>
  <Navbar />
  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>

  <WhatsApp />
  <Footer />
  <Toast />
</template>

<script setup>
import Navbar from "@/components/layout/MainNavbar.vue";
import Footer from "@/components/layout/MainFooter.vue";
import WhatsApp from "@/components/layout/WhatsApp.vue";
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";
const toast = useToast();

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBE3MuEF66CEK1zvTbpowEtpP0pTAhBcYw",
  authDomain: "notifications-8dcc0.firebaseapp.com",
  projectId: "notifications-8dcc0",
  storageBucket: "notifications-8dcc0.appspot.com",
  messagingSenderId: "524326221233",
  appId: "1:524326221233:web:fe8117e5b226893ac87990",
  measurementId: "G-HLGLJDQN18"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging();

onMessage(messaging, (payload) => {
  toast.add({ severity: 'info', summary: `${payload.notification.title}`, detail: `${payload.notification.body}`, life: 3500 });
});

getToken(messaging, { vapidKey: 'BIVf9VnWf5eMqRcN-t53gi8nTAFKVDWgE4qg1Qp_8zWLlE2Z93k85VklSLPym6J0D2YbwCPH-zZzEprKTAahQug' }).then((currentToken) => {
  if (currentToken) {
    localStorage.setItem('notificationToken', currentToken)
  } else {
    console.log('No registration token available. Request permission to generate one.');
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
});

</script>

<style lang="scss"></style>
