import { createRouter, createWebHistory } from "vue-router";
import i18n from "@/i18n";

const routes = [
    {
        path: "/",
        name: "home",
        meta: {
            title: "pagesTitle.home",
        },
        component: () => import("@/views/HomeView.vue"),
    },
    {
        path: "/packages",
        name: "packages",
        meta: {
            title: "pagesTitle.packages",
        },
        component: () => import("@/views/PackagesView.vue"),
    },
    {
        path: "/services",
        name: "services",
        meta: {
            title: "pagesTitle.services",
        },
        component: () => import("@/views/ServicesView.vue"),
    },
    {
        path: "/about",
        name: "about",
        meta: {
            title: "pagesTitle.about",
        },
        component: () => import("@/views/AboutView.vue"),
    },
    {
        path: "/contact-us",
        name: "contact-us",
        meta: {
            title: "pagesTitle.contact",
        },
        component: () => import("@/views/ContactUs.vue"),
    },
    {
        path: "/faqs",
        name: "faqs",
        meta: {
            title: "pagesTitle.faqs",
        },
        component: () => import("@/views/FaqsView.vue"),
    },
    {
        path: "/Terms-Conditions",
        name: "terms-conditions",
        meta: {
            title: "pagesTitle.terms",
        },
        component: () => import("@/views/TermsConditions.vue"),
    },
    {
        path: "/Complaints",
        name: "complaints",
        meta: {
            title: "pagesTitle.suggestions",
            requiresAuth: true,
        },
        component: () => import("@/views/ComplaintsView.vue"),
    },
    {
        path: "/coordination-request",
        name: "coordination-request",
        meta: {
            title: "pagesTitle.formatRequest",
            requiresAuth: true,
        },
        component: () => import("@/views/CoordinationRequest.vue"),
    },
    {
        path: "/done-page",
        name: "done-page",
        meta: {
            title: "pagesTitle.done",
        },
        component: () => import("@/views/DoneView.vue"),
    },
    {
        path: "/notifications",
        name: "notifications",
        meta: {
            title: "pagesTitle.notifications",
        },
        component: () => import("@/views/NotificationsView.vue"),
    },
    {
        path: "/profile",
        name: "profile",
        meta: {
            title: "pagesTitle.userAccount",
            requiresAuth: true,
        },
        component: () => import("@/views/ProfileView.vue"),
        children: [
            {
                path: "",
                name: "user-form",
                component: () => import("@/components/profile/UserForm.vue"),
            },
            {
                path: "/change-password",
                name: "change-password",
                component: () =>
                    import("@/components/profile/ChangePassword.vue"),
            },
            {
                path: "/change-phone",
                name: "change-phone",
                component: () => import("@/components/profile/ChangePhone.vue"),
            },
            {
                path: "/investigation-code",
                name: "investigation-code",
                component: () =>
                    import("@/components/profile/InvestigationCodeProfile.vue"),
            },
            {
                path: "/new-orders",
                name: "new-orders",
                meta: {
                    title: "pagesTitle.newOrders",
                },
                component: () => import("@/components/orders/NewOrders.vue"),
            },
            {
                path: "/current-orders",
                name: "current-orders",
                meta: {
                    title: "pagesTitle.currentOrders",
                },
                component: () =>
                    import("@/components/orders/CurrentOrders.vue"),
            },
            {
                path: "/expired-orders",
                name: "expired-orders",
                meta: {
                    title: "pagesTitle.oldOrders",
                },
                component: () =>
                    import("@/components/orders/ExpiredOrders.vue"),
            },
            {
                path: "/order-detailes/:id",
                name: "order-detailes",
                meta: {
                    title: "pagesTitle.orderDetailes",
                },
                component: () =>
                    import("@/components/orders/OrderDetailes.vue"),
            },
        ],
    },
    {
        path: "/auth",
        name: "auth",
        meta: {
            navLogin: true,
        },
        component: () => import("@/views/AuthView.vue"),
        children: [
            {
                path: "",
                name: "login",
                meta: {
                    title: "pagesTitle.login",
                },
                component: () => import("@/auth/LoginView.vue"),
            },
            {
                path: "/create-account",
                name: "create-account",
                meta: {
                    title: "pagesTitle.createAccount",
                },
                component: () => import("@/auth/CreateAccount.vue"),
            },
            {
                path: "/contact",
                name: "contact",
                meta: {
                    title: "pagesTitle.contact",
                },
                component: () => import("@/auth/ContactView.vue"),
            },
            {
                path: "/terms-auth",
                name: "terms-auth",
                meta: {
                    title: "pagesTitle.terms",
                },
                component: () => import("@/auth/TermsAuth.vue"),
            },
            {
                path: "/code-account",
                name: "code-account",
                meta: {
                    title: "pagesTitle.code",
                },
                component: () => import("@/auth/CodeAccount.vue"),
            },
            {
                path: "/forget-password",
                name: "forget-password",
                meta: {
                    title: "pagesTitle.forgetPass",
                },
                component: () => import("@/auth/ForgetPassword.vue"),
            },
            {
                path: "/code-password",
                name: "code-password",
                meta: {
                    title: "pagesTitle.code",
                },
                component: () => import("@/auth/CodePassword.vue"),
            },
            {
                path: "/new-password",
                name: "new-password",
                meta: {
                    title: "pagesTitle.forgetPass",
                },
                component: () => import("@/auth/NewPassword.vue"),
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        meta: {
            title: "Page Not Found",
        },
        component: () => import("@/views/PageNotFound.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!localStorage.getItem("token")) {
            next({ name: "login" });
        } else {
            next();
        }
    } else {
        next();
    }
});

router.afterEach((to) => {
    document.title = i18n.global.t(`${to.meta.title}`);
    setTimeout(() => {
        if (to.hash) {
            setTimeout(() => {
                let top = document.getElementById(`partners`).offsetTop;
                console.log(to.hash);
                console.log(top);
                window.scrollTo(0, top + 70);
            }, 100);
            
        } else {
            window.scrollTo(0, 0);
        }
    }, 300);
});

export default router;
