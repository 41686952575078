<template>
    <nav class="page-navbar" v-if="!'PageNotFound'.includes(route.name)">
        <div class="container">


            <router-link to="/" class="login-link py-3" v-if="navLogin">
                <i class="pi pi-angle-double-right"></i>
                {{ $t("nav.backHome") }}
            </router-link>

            <div class="navbar-content" v-else>
                <router-link to="/" class="logo">
                    <img :src="myLogo" class="logo-img" alt="logo" />
                </router-link>

                <div class="links" :class="{ active: active }">
                    <router-link to="/" class="logo">
                        <img :src="myLogo" alt="logo" />
                    </router-link>
                    <router-link to="/" class="link">{{ $t("nav.home") }}</router-link>
                    <router-link to="/packages" class="link">{{ $t("nav.packages") }}</router-link>
                    <router-link to="/services" class="link">{{ $t("nav.services") }}</router-link>
                    <router-link to="/about" class="link">{{ $t("nav.about") }}</router-link>
                    <router-link to="contact-us" class="link">{{ $t("nav.contact") }}</router-link>
                    <button class="link" @click="toggleLang">
                        {{ $t("nav.lang") }}
                    </button>
                </div>

                <div class="d-flex align-items-center gap-2">
                    <div class="buttons gap-2 justify-content-end">
                        <router-link to="/auth" class="main-btn transparent sm" v-if="!isLogin">{{ $t("nav.loginBtn")
                        }}</router-link>

                        <router-link to="/profile" class="sm-btn transparent" v-if="isLogin">
                            <i class="pi pi-user"></i>
                        </router-link>

                        <router-link to="/notifications" class="sm-btn transparent notifications" v-if="isLogin">
                            <i class="pi pi-bell"></i>
                            <span class="count" v-if="count">{{ count }}</span>
                        </router-link>

                        <router-link :to="{ name: 'coordination-request'}" class="router-link main-btn light sm">
                            {{ $t("nav.formatRequest") }}
                        </router-link>
                    </div>

                    <div class="sm-btn side-open transparent flex-shrink-0" @click="activeFun">
                        <i class="pi pi-bars"></i>
                    </div>
                </div>
            </div>

        </div>
    </nav>
    <div class="overlay" :class="{ active: active }" @click="activeFun"></div>
</template>

<script setup>
import i18n from "@/i18n/index";
import axios from 'axios';

import { onMounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex'
const store = useStore();

const myLogo = computed(() => {
    return store.state.logo;
});

const active = ref(false);

function activeFun() {
    active.value = !active.value;
}

const navLogin = ref(false);

const lang = ref("ar");

function getLocal() {
    const localLang = localStorage.getItem("lang");
    if (localLang) {
        i18n.global.locale = localLang;
        document.querySelector("html").setAttribute("lang", localLang);
    }
}

function toggleLang() {
    if (i18n.global.locale == "ar") {
        lang.value = "en";
    } else {
        lang.value = "ar";
    }

    localStorage.setItem("lang", lang.value);
    location.reload();

    setTimeout(() => {
        getLocal();
    }, 500);
}

const router = useRouter();
const route = useRoute();
const isLogin = ref(false);

const count = ref('');
watch(route, (newVal) => {
    active.value = false;
    if (newVal.meta.navLogin) {
        navLogin.value = true;
    } else {
        navLogin.value = false;
    }

    let localeToken = computed(() => {
        return localStorage.getItem('token');
    });

    localeToken.value ? isLogin.value = true : isLogin.value = false;

    if(localeToken.value){
        getCount();
    }
});


const getCount = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    };

    await axios.get('count-notifications', config).then(res => {
        if ((res.data.key === "unauthenticated" || res.data.key === "blocked" ) && localStorage.getItem('token')) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            router.push('/');
        } else{
            count.value = res.data.data.count;
        }
    }).catch(err => {
        console.error(err);
    });
}

onMounted(() => {
    getLocal();
    if (localStorage.getItem("token")) {
        getCount();
    }
    store.dispatch("getLogoImg");
});
</script>
