<template>
  <a :href="`http://wa.me/${phone}`" target="_blank" class="whatsapp-icon" v-if="!'PageNotFound'.includes(route.name)">
    <i class="pi pi-whatsapp ic"></i>
  </a>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from 'axios'
import { useRoute } from "vue-router";

const phone = ref('');
const route = useRoute();

// getSocials
const getSocials = async () => {
  await axios.get('main-data').then(res => {
    phone.value = res.data.data.whatsapp;
  }).catch(err => {
    console.error(err)
  });
}

onMounted(async () => {
    await getSocials();
});
</script>

<style></style>