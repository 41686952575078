<template>
    <footer class="footer" v-if="navLogin == false" >
        <div class="container">
            <div class="row footer-cards gy-5">
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="foot-content">
                        <h3 class="footer-head">{{ $t('footer.quickLinks') }}</h3>
                        <div class="footer-links">
                            <router-link to="/" class="link">{{ $t('nav.home') }}</router-link>
                            <router-link to="/services" class="link">{{ $t('nav.services') }}</router-link>
                            <router-link to="/about" class="link">{{ $t('nav.about') }}</router-link>
                            <router-link to="contact-us" class="link">{{ $t('nav.contact') }}</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-12">
                    <div class="foot-content">
                        <h3 class="footer-head">{{ $t('footer.quickLinks') }}</h3>
                        <div class="footer-links">
                            <router-link :to="{ name: 'home', hash: '#partners' }" class="link">
                                {{ $t('footer.partners') }}
                            </router-link>
                            <router-link to="/faqs" class="link">{{ $t('footer.faqs') }}</router-link>
                            <router-link to="/terms-conditions" class="link">{{ $t('footer.terms') }}</router-link>
                            <router-link to="complaints" class="link">{{ $t('footer.suggestions') }}</router-link>
                            <router-link to="coordination-request" class="link">{{ $t('nav.formatRequest') }}</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-8 col-12">
                    <div class="foot-content">
                        <h3 class="footer-head">{{ $t('footer.joinUs') }}</h3>
                        <p class="footer-info">{{ $t('footer.joinText') }}</p>

                        <form action="" class="footer-form my-4" ref="emailForm" @submit.prevent="addMail">
                            <div class="main-input">
                                <input type="text" class="input-me" name="email" v-model="email"
                                    :placeholder="$t('footer.joinPlaceholder')" />
                            </div>
                            <button type="submit" class="main-btn up">
                                {{ $t('footer.followUs') }}
                                <span class="spinner-border spinner-border-sm" v-if="loading" role="status"
                                    aria-hidden="true"></span>
                            </button>
                        </form>

                        <div class="social-icons">
                            <a :href="social.link" class="social-ic" v-for="social in socials" :key="social.name">
                                <img :src="social.icon" class="ic" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <div class="copy-right" v-if="navLogin == false">
        <div class="container">
            <div class="content">
                <p class="text">{{ $t('footer.rightsReserved') }} {{ websiteName }}</p>

                <div class="text">
                    {{ $t('footer.designedBy') }}
                    <a href="https://aait.sa/" target="_blank" class="link">{{ $t('footer.aait') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from 'axios'
import i18n from "@/i18n";

// Toast
import { useToast } from "primevue/usetoast";
const toast = useToast();

const socials = ref([]);
const websiteName = ref("");
const loading = ref(false);


// Subscribe Email
const emailForm = ref(null);
const email = ref("");

function validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true
    } else {
        return false
    }
}

const addMail = async () => {
    const fd = new FormData(emailForm.value);
    
    if (validateEmail(email.value)) {
        loading.value = true;
        await axios.post('subscribe', fd).then(res => {
            if (res.data.key == "success") {
                toast.add({ severity: 'success', summary: `${res.data.msg}`, life: 3500 });
                email.value = '';
            } else {
                toast.add({ severity: 'error', summary: `${res.data.msg}`, life: 3500 });
            }
            loading.value = false;
        }).catch(err => console.log(err));
    } else {
        toast.add({ severity: 'error', summary: i18n.global.t(`validation.email`), life: 3500 });
    }
}

// getSocials
const getSocials = async () => {
    await axios.get('main-data').then(res => {
        socials.value = res.data.data.socials
        websiteName.value = res.data.data.app_name
    }).catch(err => {
        console.error(err)
    });
}

// Toggle Navbar
const route = useRoute();
const navLogin = ref(false);

watch(route, (newVal) => {
    if (newVal.meta.navLogin || newVal.name == 'PageNotFound') {
        navLogin.value = true;
    } else {
        navLogin.value = false;
    }
});

onMounted(async () => {
    await getSocials();
});

</script>

<style></style>
