import { createStore } from "vuex";
import axios from "axios";

export default createStore({
    state: {
        slider: [],
        logo: null,
        
    },
    getters: {},
    mutations: {
        getSlider(state, slider) {
            state.slider = slider;
        },
        getLogo(state, logo) {
            state.logo = logo;
        },
    },
    actions: {
        getSliderAction(context, slider) {
            context.commit("getSlider", slider);
        },
        async getLogoImg({ commit }) {
            try {
                await axios.get("main-data").then((res) => {
                    commit("getLogo", res.data.data.logo);
                });
            } catch (error) {
                console.log(error);
            }
        },
    },
    modules: {},
});
